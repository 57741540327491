import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import RocketIcon from "../../assets/svgs/rocket.svg";
import { Link } from "./link";

const goUp = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Footer = ({ path }) => {
  const isActive = path === "/";
  return (
    <div
      className={ctl(`
        max-w-[1800px]
      `)}
    >
      <div
        className={ctl(`
          pt-vgap-md
          flex
          border-t border-dashed border-zd-gray
          xl:-ml-hgap-md-x2
          xl:pl-hgap-md-x2
          xl:-mr-hgap-md
          xl:pr-hgap-md
        `)}
      >
        <div
          className={ctl(`
          flex items-center
        `)}
        >
          <Link
            to="/"
            className="zd-invert-color-link"
            activeClassName="pointer-events-none"
            tabIndex={isActive ? -1 : 0}
          >
            takazudomodular.com
          </Link>
        </div>
        <div className="w-full">
          <button
            className="flex text-zd-white zd-invert-color-link ml-auto items-center"
            onClick={goUp}
          >
            <span className="underline mr-[6px] hidden sm:block">
              ページの先頭へ
            </span>
            <RocketIcon className="h-[40px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export { Footer };
